.space-planning {
    padding: 0 0 spacer(14);

    .carousel:not(.zoom) {
        position: inherit;
    }

    .carousel-inner {
        padding-top: 0;
    }

    .swiper-slide {
        padding-bottom: spacer(10);
    }

    .space-planning-item:not(.zoom) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        @media (--lg) {
            flex-wrap: nowrap;
        }

        h3 {
            font-family: var(--font-saritha);

            span {
                font-family: var(--font-watercolor);
                font-size: prem(14);
                color: col(blue);

                @media (--lg) {
                    font-size: prem(24);
                }

                span {
                    font-family: var(--font-bradley);
                    font-weight: 600;
                    font-size: prem(48);
                }
            }
        }

        ul {
            span {
                font-family: var(--font-watercolor);
                font-size: prem(8);
                color: col(blue);

                @media (--lg) {
                    font-size: prem(10);
                }
            }
        }

        li {
            margin: spacer(4) 0 0;

            &:first-child {
                margin: 0;
            }

            ul {
                padding: spacer(4) 0 0 spacer(4);
                list-style: none;
            }
        }

        .media-wrapper {
            @media (--lg) {
                margin: spacer(-5) 0 0 percentage(-2/10);
            }
        }
    }

    .space-planning-d,
    .space-planning-s {
        flex-grow: 0;
        flex-shrink: 0;
        width: percentage(1);

        @media (--lg) {
            width: percentage(.5);
        }
    }

    .space-planning-s {
        > ul {
            list-style: none;
            margin: 0 auto;
            max-width: 400px;
            padding: spacer(5) 0 0;

            @media (--lg) {
                max-width: none;
                padding: 0;
            }
        }

        &.padded {
            @media (--lg) {
                padding-bottom: spacer(20);
            }
        }
    }

    .space-planning-d {
        padding: spacer(10) 0 0;
        margin: 0 auto;
        max-width: 400px;

        @media (--lg) {
            max-width: none;
            padding: spacer(10) 0 0 spacer(14);
        }

        > ul {
            margin: spacer(8) 0 0 spacer(3);
        }
    }
}

.carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: percentage(1);
    height: percentage(1);

    @media (--lg) {
        padding: 0;
    }

    &-inner {
        position: relative;
        padding-top: percentage(8/16);
    }

    &-wrapper {
        padding: spacer(10) 0 0;

        &-space-planning {
            padding: spacer(26) 0 0;

            @media (--lg) {
                padding-bottom: spacer(14);
            }
        }
    }

    .zoom-close {
        position: absolute;
        right: spacer(5);
        top: spacer(5);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        text-transform: uppercase;
        color: black;
        font-family: var(--font-bradley);
        font-size: prem(16);
        letter-spacing: .06em;
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-slide {
        transform-origin: center 0;
        transform: scale(.8) translate3d(0, 20px, 0);
        opacity: 0;
        transition: .5s transform var(--ease), .25s opacity ease-in-out;
        position: relative;
        pointer-events: none;

        .zoom-slide {
            position: absolute;
            right: spacer(2);
            bottom: spacer(2);
            background: rgba(#111, .8);
            opacity: 0;
            visibility: hidden;
            transition: .25s opacity, .25s visibility;
            white-space: nowrap;
            color: white;
            font-family: var(--font-bradley);
            font-size: prem(14);
            padding: spacer() spacer(2);
            border-radius: 20px;

            &::before {
                content: 'agrandir';
            }
        }

        .media {
            opacity: 1;

            img {
                transition: .5s opacity var(--ease);
            }

            &.loading {
                background-image: svg-inline(placeholder);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 80px auto;

                img {
                    opacity: 0;
                }
            }
        }

        &.swiper-slide-active {
            pointer-events: all;
            transform: none;
            opacity: 1;
        }

        &:hover {
            .zoom-slide {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .swiper-pagination {
        bottom: spacer(-10);
        left: 0;
        right: 0;

        @media (--lg) {
            right: inherit;
            left: calc(#{percentage(1)} + #{spacer(4)});
            bottom: 0;
            top: 0;
            width: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        background: url('../images/dot-ouvert.png') center center no-repeat;
        background-size: 16px auto;
        margin: 0 spacer();
        transition: .25s transform var(--ease);
        flex-grow: 0;
        flex-shrink: 0;

        @media (--lg) {
            margin: spacer() 0;
        }

        &.swiper-pagination-bullet-active {
            background-image: url('../images/dot-ferme.png');
            transform: rotate(360deg);

            .space-planning & {
                background-size: 33px auto;
            }
        }

        .space-planning & {
            width: 33px;
            height: 33px;
            background-size: 38px auto;
            opacity: 1;

            @media (--lg) {
                margin: spacer(2) 0;
            }
        }
    }

    .perspective-item {
        background: #f5f5f5;

        .media {
            padding-top: percentage(8/16);
        }

        &.zoom {
            display: none;
        }
    }

    .space-planning-item {
        .media {
            padding-top: percentage(790/1300);
        }

        &.zoom {
            display: none;

            .media {
                padding-top: percentage(884/1956);
            }
        }
    }

    &.zoom {
        position: fixed;
        background: white;
        z-index: 200;
        display: flex;
        align-items: center;
        justify-content: center;

        .swiper-container {
            flex-grow: 0;
            flex-shrink: 0;
            width: percentage(.9);

            @media (--lg) {
                max-width: 140vh;
            }
        }

        .space-planning-item,
        .perspective-item {
            display: none;

            &.zoom {
                display: block;
            }
        }

        .zoom-slide {
            display: none;
        }

        .zoom-close {
            opacity: 1;
            visibility: visible;
        }
    }
}

.perspective-carousel {
    padding: 0 0 spacer(10);

    @media (--lg) {
        padding: 0;
    }

    .carousel-wrapper {
        margin: 0 calc(var(--gutter) * -4);

        @media (--md) {
            margin: 0;
        }
    }
}
