.app-content {
    .app-ready & {
        display: block;
    }
}

.content-section {
    padding: spacer(15) 0 0;

    @media (--lg) {
        padding-top: spacer(25);
    }

    h2 {
        margin: 0;

        &.centered {
            text-align: center;

            @media (--md) {
                text-align: left;
            }
        }

        &.md-right {
            @media (--md) {
                text-align: right;
            }
        }
    }

    p,
    ul {
        margin: spacer(4) 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        a {
            color: col(blue-light);
        }
    }

    .caps {
        text-transform: uppercase;
    }

    .acces {
        &-1 {
            order: 1;

            @media (--lg) {
                order: 2;
            }
        }

        &-2 {
            order: 2;

            @media (--lg) {
                order: 1;
            }
        }

        &-3 {
            order: 3;

            @media (--lg) {
                display: none;
            }
        }
    }

    .plan-transport {
        margin: spacer(10) calc(#{percentage(-.1)} - var(--gutter) * 3) spacer(6);

        @media (--lg) {
            margin: 0 0 spacer(6);
        }

        .media {
            padding-top: percentage(818/1144);
        }
    }

    .map-link,
    .pdf-link {
        text-align: center;
        padding: 0 0 30px;

        figure {
            padding-top: 0;
            width: 100px;
            height: 100px;
            position: absolute;
            right: -37px;
            top: -30px;
            z-index: -1;
            pointer-events: none;
        }

        span {
            display: inline-block;
            transition: .25s all var(--ease);
            text-decoration: underline;
        }

        a {
            display: inline-block;
            position: relative;
            font-size: prem(24);

            @media (--md) {
                font-size: prem(27);
            }

            &:hover {
                span {
                    transform: translate3d(0, 3px, 0);
                }
            }
        }
    }

    .pdf-link {
        padding: 180px 0 0;

        figure {
            right: inherit;
            left: percentage(.5);
            top: percentage(.5);
            transform: translate3d(-50%, -50%, 0);
            width: 130px;
            height: 130px;
        }
    }

    .acces-transport {
        position: relative;
        z-index: 0;

        @media (--lg) {
            padding: 0 percentage(1/10) 0 0;
        }
    }

    .velo {
        margin-left: calc(var(--gutter) * -2);
        margin-right: calc(var(--gutter) * -2);
        overflow: hidden;

        .media {
            padding-top: percentage(2049/3656);
        }

        @media (--lg) {
            margin: 0;
        }

        &.mob {
            margin-top: spacer(10);

            @media (--lg) {
                display: none;
            }
        }

        &.dk {
            display: none;

            @media (--lg) {
                display: block;
                margin-left: percentage(-1/4);
                margin-top: spacer(-4);
                position: relative;
                z-index: -1;
            }
        }
    }

    .transport,
    .chiffres {
        flex: 1 1 0;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: center;

        .media {
            padding-top: 0;
            position: absolute;
            z-index: -1;
            width: 160px;
            height: 160px;
            transition: .5s transform var(--ease);
            background: none;
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            position: relative;
            z-index: 0;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    .transport {
        max-width: 400px;
        margin: 0 auto;

        &-item {
            margin: 0;
            padding: spacer(10) 0;
            width: percentage(.5);

            @media (--md) {
                flex: 1 1 0;
                width: 0;
            }

            &:hover {
                .media {
                    transform: scale(1.2) rotate(2deg);
                    transition-duration: 2s;
                }
            }
        }

        &-wrapper {
            padding: spacer(6) 0 spacer(3);
            display: flex;
            flex-wrap: wrap;
        }

        &-aside {
            flex-grow: 0;
            flex-shrink: 0;
            width: percentage(1);
            padding: 0 0 spacer(10);

            @media (--md) {
                flex-grow: 0;
                flex-shrink: 0;
                width: percentage(2.5/4);
                padding: 0 spacer(3) 0 0;
            }
        }

        &.stair {
            justify-content: flex-start;

            .transport-item {
                &:nth-child(2n) {
                    margin-left: percentage(.5);

                    @media (--md) {
                        margin: 0;
                    }
                }
            }
        }
    }

    .mobilite {
        width: percentage(10/12);
        padding: spacer(3) 0 spacer(6);

        @media (--sm) {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        @media (--lg) {
            width: percentage(8/12);
            text-align: left;
            margin-left: 0;
            padding: spacer(6) 0;
        }
    }

    .lignes {
        position: relative;
        margin: 0 auto;
        max-width: 300px;
        z-index: 0;

        @media (--lg) {
            max-width: none;
        }

        ul {
            list-style: none;
        }

        li {
            margin: spacer(7) 0 0 spacer(20);
            width: spacer(30);
            font-size: prem(16);

            @media (--xs) {
                margin-left: spacer(33);
            }

            @media (--lg) {
                font-size: prem(20);
                margin-top: spacer(2);
                margin-left: spacer(24);
                width: auto;
            }

            &:first-child {
                margin-top: 0;

                @media (--lg) {
                    margin-left: 0;
                }
            }

            &:last-child {
                margin-top: spacer(20);
                margin-left: 0;

                @media (--lg) {
                    margin-top: spacer(10);
                    margin-left: spacer(40);
                }
            }
        }

        span {
            color: col(pink);
        }

        &-illustration {
            width: 240px;
            position: absolute;
            left: percentage(-.4);
            z-index: -1;

            @media (--xs) {
                left: -80px;
            }

            @media (--lg) {
                top: spacer(48);
            }

            .media {
                padding-top: percentage(2544/2702);
            }
        }
    }

    .b-label {
        margin: spacer(6) 0 0;
        font-family: var(--font-watercolor);
        font-size: 6vw;
        color: col(blue);
        line-height: 4;
        position: relative;
        display: inline-block;

        @media (--sm) {
            font-size: prem(30);
        }

        @media (--md) {
            font-size: 2.5vw;
            margin: spacer(-5) 0 0;
        }

        @media (--xl) {
            font-size: prem(36);
        }

        span {
            font-size: 3vw;
            color: col(blue);
            position: absolute;
            top: percentage(.35);
            right: 0;

            @media (--md) {
                font-size: prem(16);
            }

            @media (--lg) {
                font-size: prem(20);
            }
        }
    }

    .b-bs {
        display: block;
        text-align: center;
        padding-top: spacer(4);

        @media (--lg) {
            text-align: left;
            display: inline-block;
            padding-top: 0;
        }
    }

    .b-bs-label {
        text-align: center;

        @media (--lg) {
            text-align: left;
        }
    }

    .s-label {
        font-family: var(--font-watercolor);
        font-size: 3vw;
        line-height: 4;
        color: col(blue);
        margin: spacer(2) 0 0;

        @media (--sm) {
            font-size: prem(14);
        }

        @media (--lg) {
            font-size: prem(18);
        }

        span {
            font-size: prem(18);
            display: inline-block;
            font-family: var(--font-default);
            color: black;
        }

        & + .label {
            margin-top: 0;
        }

        .bdl {
            font-family: var(--font-bradley);
            font-weight: 600;
            font-size: prem(36);
            color: col(blue);

            &-small {
                font-size: prem(24);
            }
        }

    }

    .s-top-indent {
        margin-top: spacer(8);

        & + .s-label {
            margin-top: spacer(2);
        }
    }

    .mb-indent {
        padding: spacer(6) 0 0 percentage(2/12);

        @media (--lg) {
            padding-left: spacer(6);
        }

        @media (--xl) {
            padding-left: spacer(10);
        }
    }

    .lb-indent {
        @media (--lg) {
            padding-left: spacer(6);
        }

        @media (--xl) {
            padding-left: spacer(10);
        }
    }

    .chiffres-illustration {
        @media (--md) {
            padding: 100px 0 0;
            margin: 0 -50px 0 -100px;
            height: percentage(1);
        }

        .media {
            padding-top: percentage(822/1240);
        }
    }

    .chiffres-illustration-2 {
        display: flex;
        align-items: center;

        .media {
            width: percentage(1);

            @media (--md) {
                padding-top: percentage(10/16);
                height: auto;
            }
        }
    }

    .chiffres-illustration-3 {
        display: flex;
        align-items: center;
        margin: 0 calc(var(--gutter) * -2);

        @media (--md) {
            margin: 0 0 0 percentage(-1/10);
            height: percentage(1);
        }

        .media {
            width: percentage(1);
            height: 400px;

            @media (--md) {
                padding-top: percentage(1968/3219);
                height: auto;
            }
        }
    }

    .chiffres-illustration-4 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: auto;
        padding: 0 percentage(1/12);

        @media (--lg) {
            padding: 0;
        }

        .media {
            width: percentage(1);
            padding-top: percentage(660/560);
            height: auto;
        }

        .legend {
            width: percentage(1);
            font-size: prem(14);
        }
    }

    .col-invert-1 {
        order: 1;

        @media (--md) {
            order: 2;
        }
    }

    .col-invert-2 {
        order: 2;

        @media (--md) {
            order: 1;
        }
    }

    .chiffres {
        max-width: 400px;
        margin: 0 auto;
        padding: spacer(6) 0 0;

        @media (--md) {
            justify-content: flex-start;
            max-width: none;
            padding: 0;
        }

        .media {
            width: 210px;
            height: 210px;
        }

        &-item {
            width: 230px;
            min-height: 200px;
            margin: 0;

            @media (--md) {
                margin-left: percentage(1/10);
            }

            &.nomarg {
                margin-left: 0;

                @media (--md) {
                    margin-left: calc(#{percentage(1/5)} - 80px);
                }

                @media (--lg) {
                    margin-left: calc(#{percentage(1/4)} - 80px);
                }
            }

            p {
                margin: 0;

                strong {
                    display: block;
                    font-family: var(--font-watercolor);
                    font-size: 3vw;
                    line-height: 3;
                    color: col(blue);
                    padding: spacer(2) 0;

                    @media (--sm) {
                        font-size: prem(14);
                    }

                    @media (--lg) {
                        font-size: prem(16);
                        white-space: nowrap;
                    }
                }

                span {
                    display: block;
                    font-size: prem(18);
                    font-family: var(--font-default);
                    color: black;
                    margin: 0 spacer(4);
                }

                .bdl {
                    display: inline-block;
                    font-family: var(--font-bradley);
                    font-weight: 600;
                    font-size: prem(50);
                    color: col(blue);
                }
            }

            &:first-child {
                margin-top: 0;
            }

            &:nth-child(2n+1) {
                margin-right: spacer(22);

                @media (--md) {
                    margin-right: 0;
                }
            }

            &:nth-child(2n) {
                margin-left: spacer(20);

                @media (--md) {
                    margin-left: percentage(4/10);
                }
            }
        }

        &-indent {
            padding: spacer(10) 0 0 percentage(4/12);
            position: relative;

            @media (--md) {
                padding-left: percentage(1/5);
            }

            @media (--lg) {
                padding-left: percentage(1/4);
            }

            &-illustration {
                position: absolute;
                left: -20px;
                bottom: 100px;
                width: 100px;

                @media (--md) {
                    left: initial;
                    right: percentage(1);
                    bottom: -40px;
                    width: 220px;
                }

                .media {
                    padding-top: percentage(431/418);
                    transform: rotate(-46.6deg);
                }
            }
        }
    }

    .canal {
        &-content {
            margin: spacer(10) 0 0;
        }

        &-media {
            margin: 0 0 spacer(4) calc(var(--gutter) * -2);

            @media (--md) {
                margin-left: 0;
            }

            .media {
                padding-top: percentage(900/1880); // 1908
            }
        }

        &-media-in {
            margin: spacer(6) calc(var(--gutter) * -2);

            @media (--md) {
                margin-left: 0;
                margin-right: 0;
            }

            .media {
                padding-top: percentage(2596/3404);
            }
        }

        &-media-in-2 {
            .media {
                padding-top: percentage(2030/3186);
            }
        }

        &-media-ourcq {
            margin: spacer(6) calc(var(--gutter) * -2);

            @media (--md) {
                margin: 0;
            }

            .media {
                padding-top: percentage(1577/3048);
            }
        }

        &-media-bordered {
            margin: spacer(6) 0 0;
        }

        &-media-bordered-1 {
            margin-left: percentage(-.1);

            .media {
                padding-top: percentage(603/1087);
            }
        }

        &-media-bordered-2 {
            margin-top: 46px;

            @media (--lg) {
                margin-top: 50px;
            }

            .media {
                padding-top: percentage(521/723);
            }
        }
    }

    .street-art {
        &-content {
            margin: spacer(10) 0 0;
        }

        &-media {
            margin: spacer(6) 0 0;

            @media (--md) {
                margin: 0;
            }

            .media {
                padding-top: percentage(1638/1815);
            }
        }

        &-media-bordered {
            margin: spacer(6) 0 0;

            @media (--md) {
                margin: 0;
            }

            .media {
                padding-top: percentage(610/729);
            }
        }

        &-media-bordered-2 {
            .media {
                padding-top: percentage(558/977);
            }
        }

        &-media-1 {
            @media (--md) {
                margin-left: spacer(8);
                margin-right: spacer(-2);
            }

            @media (--lg) {
                margin-left: 0;
            }
        }

        &-media-2 {
            @media (--md) {
                margin-top: percentage(-2/10);
                margin-left: spacer(-8);
                margin-right: spacer(8);
                margin-bottom: spacer(6);
            }

            @media (--lg) {
                margin-top: 0;
                margin-bottom: spacer(14);
            }

            @media (--xl) {
                margin-top: percentage(-2/10);
            }
        }

        &-media-3 {
            @media (--md) {
                margin-left: spacer(-2);
                margin-right: spacer(-4);
            }
        }

        &-graffiti {
            margin: spacer(20) 0 0;

            .media {
                padding: 0;
                height: 260px;

                @media (--lg) {
                    height: auto;
                    padding-top: percentage(481/2880);
                }

                img {
                    object-fit: fill;
                    width: auto;

                    @media (--lg) {
                        object-fit: cover;
                        width: percentage(1);
                        transform: none;
                    }
                }
            }

            &.desktop {
                display: none;

                @media (--sm) {
                    display: block;
                }
            }

            &.mobile {
                @media (--sm) {
                    display: none;
                }

                .media {
                    padding-top: percentage(542/750);
                    height: auto;
                }
            }
        }
    }

    .content-subsection {
        margin: spacer(20) 0 0;

        &.nomarge {
            margin: 0;
        }
    }

    &.o-hidden {
        overflow: hidden;
    }
}

.content-section-chiffres {
    @media (--lg) {
        padding-bottom: spacer(5);
    }
}

.space-planning {
    margin: spacer(20) 0 0;
    position: relative;

    @media (--lg) {
        padding: 0;
    }

    &-bg {
        background: col(blue);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .media {
            background: none;
            padding: 0;
            height: percentage(1);
        }
    }

    &-content {
        position: relative;

        h2 {
            position: absolute;
            top: spacer(10);
            right: 0;
        }
    }
}

.contact-content {
    padding: spacer(4) 0 0;

    h3 {
        margin: 70px 0 0;
        text-align: center;
        font-size: prem(18);
        font-family: var(--font-bradley);
    }

    .contact {
        display: flex;
        flex-wrap: wrap;

        @media (--xl) {
            justify-content: space-between;
        }

        &-item {
            flex-grow: 0;
            flex-shrink: 0;
            width: percentage(1);
            text-align: center;
            margin: spacer(10) 0 0;

            @media (--md) {
                width: percentage(1/3);
            }

            svg {
                width: 80px;
                margin: 0 0 spacer(4);
            }

            p {
                padding-bottom: 10px;
            }

            a {
                display: inline-block;
                position: relative;
                padding: 0 0 0 spacer(14);
                font-size: prem(18);
                transition: .25s all var(--ease);

                @media (--md) {
                    font-size: prem(16);
                }

                @media (--lg) {
                    font-size: prem(18);
                }

                .bg,
                .icon {
                    position: absolute;
                    top: percentage(.5);
                    left: 0;
                    width: 60px;
                    height: 60px;
                    transform: translate3d(0, -30px, 0);

                    .media {
                        padding-top: percentage(1);
                        transition: .25s all var(--ease);
                    }
                }

                &:hover {
                    color: col(blue);

                    .media {
                        transform: translate3d(-5px, 0, 0);
                    }
                }
            }

            .logo {
                margin: 0 auto;
            }

            .logo-bnp {
                padding: 5px 0;
                width: 145px;

                .media {
                    padding-top: percentage(35/145);
                }
            }

            .logo-jll {
                width: 101px;

                .media {
                    padding-top: percentage(46/101);
                }
            }

            .logo-cbre {
                padding: 8px 0;
                width: 109px;

                .media {
                    padding-top: percentage(31/109);
                }
            }

            .line {
                padding: spacer(4) 0;
            }
        }

        &-media {
            margin: spacer(10) 0 0;

            @media (--md) {
                margin: 0;
                height: percentage(1);
                display: flex;
                align-items: center;
            }

            .media {
                width: percentage(1);
                padding-top: percentage(2626/2105);
            }
        }
    }

    .co-promo {
        margin: 30px 0 0;

        @media (--md) {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .co-promo-logo {
        max-width: 260px;
        width: percentage(.8);
        margin: 0 auto;

        @media (--md) {
            margin: 0 40px;
        }
    }

    .logo-ga {
        .media {
            padding-top: percentage(248 / 520);
        }
    }

    .logo-bnp {
        .media {
            padding-top: percentage(276 / 446);
        }
    }

    .google-map {
        margin: spacer(20) auto 0;
        max-width: 706px;

        iframe {
            width: percentage(1);
            border: none;
        }
    }
}

.content-mentions {
    h1 {
        @include title-2;
        font-size: prem(72);
    }

    h2 {
        font-family: var(--font-default);
        font-size: prem(18);
        margin: spacer(6) 0 0;
    }

    ul {
        list-style: none;
    }
}

.video-player {
    background: #f9ffd1;
    padding: 120px 0;
    position: relative;

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;

        .media {
            padding: 0;
            height: percentage(1);
        }
    }

    &-content {
        position: relative;
        padding: percentage(10/16) 0 0;
        background: rgba(0, 0, 0, .2);

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: percentage(1);
            height: percentage(1);
        }
    }
}
