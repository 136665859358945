#orejime {
    .app-ready & {
        transition-delay: 4s;
        visibility: visible;
        opacity: 1;
    }
}

[class^="orejime-"] {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: var(--font-avenir);
    font-size: inherit;
    color: inherit;
    vertical-align: baseline;
    cursor: default;
    float: none;
    width: auto;
    text-align: left;
    font-weight: normal;
}

.orejime-Main,
.orejime-Modal {
    font-size: 1em;
    line-height: 1.5;
}

.orejime-Main {
    background: #66b2e4;
    position: fixed;
    z-index: 100;
    width: 100%;
    bottom: 0;
    font-size: .8em;
}

@media (min-width: 990px) {
    .orejime-Main {
        box-shadow: 0 4px 6px 0 rgba(255, 255, 255, .2), 5px 5px 10px 0 rgba(255, 255, 255, .19);
    }
}

@media (max-width: 989px) {
    .orejime-Main {
        border: none;
        border-radius: 0;
    }
}

.orejime-Notice--hidden {
    display: none !important;
}

.orejime-Notice-description {
    color: #eeeeee;
    margin-bottom: .5em;
}

.orejime-Notice-purposes {
    color: #eeeeee;
}

.orejime-Notice-changes {
    color: #eeeeee;
    margin-bottom: .5em;
    font-weight: bold;
}

.orejime-Notice-actionItem {
    display: inline-block;
}

.orejime-Notice-learnMoreButton {
    display: inline-block;
    background: white;
    border-color: black;
    color: black;
}

.orejimeHtml-WithModalOpen {
    height: 100%;
}

.orejimeHtml-WithModalOpen body {
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.orejime-ModalOverlay {
    z-index: 1000;
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.orejime-ModalWrapper {
    z-index: 1001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    width: 640px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.orejime-Modal {
    background: white;
    color: #eeeeee;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .2), 5px 5px 10px 0 rgba(0, 0, 0, .19);
}

.orejime-Modal-header {
    background: col(blue);
    padding: calc(var(--gutter) * 4);
    border: none;

    @media (max-width: 640px) {
        padding: 2em;
    }
}

.orejime-Modal-description {
    font-family: var(--font-bradley);
    font-size: 16px;
    line-height: 20px;
    padding: spacer(2) 0 0;

    a {
        font-family: var(--font-bradley);
    }
}

.orejime-Modal-title {
    margin: 0;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    display: block;
    font-family: var(--font-saritha);
    font-weight: bold;
    padding-right: 20px;
}

.orejime-CloseIcon {
    stroke: currentColor;
    width: 12px;
}

.orejime-Modal-body {
    padding: calc(var(--gutter) * 4);

    @media (max-width: 640px) {
        padding: 2em;
    }

    .orejime-AppToggles {
        display: flex;
        justify-content: space-around;
        padding: 0 0 spacer(4);
    }

    .orejime-Button {
        color: black;
        border-color: col(blue);
        // margin: 0 spacer(2);

        &:hover {
            background: col(blue);
            border-color: col(blue);
        }
    }

    .orejime-AppItem-label {
        font-weight: 600;
        font-size: 16px;
        color: black;
    }

    .orejime-AppItem-description {
        font-family: var(--font-bradley);
        font-size: 14px;
        color: black;
        padding: 5px 0 0;
    }
}

.orejime-Modal-footer {
    padding: spacer(4) 0;
    border-top: 1px solid col(blue);
    display: flex;
    justify-content: center;
    align-items: center;

    .orejime-Button {
        border-color: col(blue);
        background: col(blue);
    }
}

.orejime-Modal-poweredByLink {
    display: none;
    cursor: pointer;
    text-decoration: underline;
    position: absolute;
    right: 1em;
    color: #aaaaaa;
    font-size: .8em;
    padding: 6px 0;
}
.orejime-Modal-privacyPolicyLink {
    cursor: pointer;
    text-decoration: underline;
    color: inherit;
}

.orejime-Modal-privacyPolicyLink:hover,
.orejime-Modal-privacyPolicyLink:focus,
.orejime-Modal-privacyPolicyLink:active,
.orejime-Modal-poweredByLink:hover,
.orejime-Modal-poweredByLink:focus,
.orejime-Modal-poweredByLink:active {
    color: inherit;
}

.orejime-Modal-privacyPolicyLink:hover,
.orejime-Modal-privacyPolicyLink:focus,
.orejime-Modal-poweredByLink:hover,
.orejime-Modal-poweredByLink:focus {
    text-decoration: none;
}

.orejime-Modal-poweredByLink:hover,
.orejime-Modal-poweredByLink:focus,
.orejime-Modal-poweredByLink:active {
    color: #aaaaaa;
}

.orejime-AppList {
    display: block;
    padding: 0;
    margin: 0;
}

.orejime-AppList-item {
    display: block;
    position: relative;
    line-height: 1.25;
    vertical-align: middle;
    padding-left: 60px;
    min-height: 40px;
    margin-top: .7em;
}

.orejime-AppList-item:first-child {
    margin-top: 0;
}

.orejime-AppItem-title {
    font-weight: 600;
    cursor: pointer;
}

.orejime-AppItem-description {
    font-size: .8em;
}

.orejime-AppItem-purposes {
    font-size: .8em;
    color: #aaaaaa;
}

.orejime-AppItem-optOut,
.orejime-AppItem-required {
    padding-left: .2em;
    font-size: .8em;
    color: #aaaaaa;
}

.orejime-AppItem-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 50px;
    height: 30px;
}

.orejime-AppItem-switch {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 50px;
    height: 30px;
}

.orejime-AppItem-slider {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .2), 5px 5px 10px 0 rgba(0, 0, 0, .19);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #aaaaaa;
    -webkit-transition: .4s;
    transition: .4s;
    width: 50px;
    display: inline-block;
    border-radius: 30px;
    border: 2px solid transparent;
}

.orejime-AppItem-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
    border: 1px solid #cccccc;
}

.orejime-AppItem-switchLabel {
    position: absolute;
    width: 50px;
    top: 34px;
    overflow: ellipsis;
    font-size: 10px;
    text-align: center;
    padding: 4px 0 0;
}

.orejime-AppItem-switch--disabled .orejime-AppItem-slider {
    cursor: default;
    opacity: .5;
}

.orejime-AppItem-switch--disabled .orejime-AppItem-switchLabel {
    color: #aaaaaa;
}

.orejime-AppItem-input:focus + .orejime-AppItem-label .orejime-AppItem-slider {
    border-color: #ffffff;
    outline-offset: 2px;
}

.orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider {
    background-color: col(blue);
}

.orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background: white;
    border: none;
}

.orejime-AppToggles {
    margin-bottom: 1em;
    display: block;
}

.orejime-AppToggles-item {
    display: inline;
}

/* Zo */
.orejime-Notice {
    max-width: 100vw;
    margin: 0 auto;
    padding-left: var(--gutter);
    padding-right: var(--gutter);

    @media (max-width: 640px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.orejime-Notice-body {
    display: flex;
    flex-wrap: wrap;
    padding: calc(var(--gutter) * 2);

    .orejime-Notice-text {
        width: percentage(1);

        @media (--lg) {
            width: percentage(1/2);
        }
    }

    .orejime-Notice-actions {
        display: flex;
        width: 100%;
        text-align: right;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (--lg) {
            width: percentage(1/2);
            justify-content: flex-end;
        }

        .orejime-Notice-actionItem {
            width: percentage(.96/2);
            margin: spacer(2) 0 0;

            @media (--sm) {
                width: percentage(.8/3);
            }

            @media (--lg) {
                width: auto;
                min-width: 140px;
                margin-left: spacer(3);
            }

            button {
                width: percentage(1);
            }
        }

        .orejime-Notice-actionItem--info {
            width: percentage(1);

            @media (--sm) {
                width: percentage(1.3/3);
            }

            @media (--lg) {
                width: auto;
                min-width: 140px;
            }
        }
    }

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
    }

    p.orejime-Notice-description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
    }
}

.orejime-Button {
    color: white;
    padding: 10px 15px;
    font-family: var(--font-avenir);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid white;
    text-align: center;
    transition: .25s all var(--ease);

    &:hover {
        transition: all .3s ease 0s;
        background: black;
        border-color: white;
        color: white;
    }
}

.orejime-Notice-learnMoreButton {
    border-color: black;
    color: black;
}

.orejime-Button[disabled] {
    opacity: .75;
}

.orejime-Button--save,
.orejime-Button--accept {
    box-sizing: border-box;
}

.orejime-Modal-closeButton {
    color: inherit;
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
    // background-image: url("data:image/svg+xml,%3Csvg width='6' height='15' viewBox='0 0 6 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.30294 14.7275C1.30294 9.97054 4.60759 5.48722 4.62891 0.814453' stroke='%23CCCCCC'/%3E%3C/svg%3E");
    // background-repeat: no-repeat;
    // background-size: 14px 14px;
    // background-position: center;
    width: 20px;
    height: 20px;
    // border: 1px solid #cccccc;
}
