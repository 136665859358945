.main-footer {
    padding: spacer(5) 0;

    .app-ready & {
        display: block;
    }
}

.main-navigation-footer {
    position: relative;
    transform: none;
    background: none;
    height: auto;

    ul {
        padding: spacer(8) 0;
        flex-direction: row;
        justify-content: center;
        opacity: 1;

        @media (--md) {
            justify-content: flex-end;
            width: percentage(11.6/12);
        }

        @media (--lg) {
            width: percentage(8/12);
        }
    }

    li {
        font-size: prem(26);
        opacity: 1;
    }

    .footer-brand {
        display: block;
        margin: 0 auto;
        width: 130px;

        @media (--md) {
            position: absolute;
            z-index: 101;
            top: 20px;
            left: percentage(1.5/12);
            transform: translate3d(#{percentage(-.5)}, 0, 0);
        }

        svg {
            width: 130px;
        }
    }
}
