.v-guide {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    pointer-events: none;
    opacity: 0;
    z-index: 100000;

    &::before {
        content: '';
        width: 1px;
        height: 100vh;
        position: fixed;
        left: 50vw;
        background: red;
        z-index: 2;
    }

    .col {
        height: 100vh;
        position: relative;

        &::before,
        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: var(--gutter);
            background: rgba(purple, .04);
        }

        &::before {
            left: 0;
            border-left: 1px dashed #0000dd;
        }

        &::after {
            right: 0;
        }

        &:last-child {
            &::after {
                border-right: 1px dashed #0000dd;
            }
        }
    }

    &.active {
        opacity: 1;
    }
}
